<template>
  <v-card>
    <v-card-title>
      <span class="headline">{{ formtitle }}</span>
    </v-card-title>

    <v-card-text>
      <v-form ref="form" v-model="isValid" :disabled="action === 'see'">
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field
                dense
                outlined
                v-model="form.name"
                :label="$t('__charge_plan_form_name') + '*'"
                required
                :rules="[requiredRule]"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col :cols="isMobile ? 12 : 6">
              <date-picker
                :date.sync="form['start_time']"
                :date-label="$t('__charge_plan_form_start_time')"
                :locale="$i18n.locale"
                :outlined="true"
                :dense="true"
              />
            </v-col>
            <v-col :cols="isMobile ? 12 : 6">
              <date-picker
                :date.sync="form['end_time']"
                :date-label="$t('__charge_plan_form_end_time')"
                :locale="$i18n.locale"
                :outlined="true"
                :dense="true"
                :min-date="form['start_time']"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <div class="text-h7 mb-2">
                {{ $t("__charge_plan_form_contract_duration_title") }}
              </div>
              <v-row>
                <v-col cols="4">
                  <v-text-field
                    type="number"
                    dense
                    outlined
                    required
                    :rules="[requiredRule]"
                    :label="$t('__charge_plan_form_contract_duration')"
                    v-model.number="form['contract_duration']"
                  ></v-text-field>
                </v-col>
                <v-col cols="8">
                  <v-select
                    :items="contractDuration"
                    item-text="ch"
                    item-value="en"
                    :label="$t('__charge_plan_form_contract_duration_unit')"
                    v-model="form['contract_duration_unit']"
                    outlined
                    dense
                  />
                </v-col>
              </v-row>
              <v-card outlined style="border: 1px solid gray">
                <v-card-title>
                  {{ $t("__charge_plan_form_contract_terms") }}
                </v-card-title>
                <VueEditor v-model="form['contract_terms']" />
              </v-card>
            </v-col>
          </v-row>
          <v-radio-group v-model="feeChoosed" row>
            <v-radio
              :label="$t('__charge_plan_form_payasyougo')"
              value="payAsYouGo"
            ></v-radio>
            <v-radio
              :label="$t('__charge_plan_form_regular')"
              value="regular"
            ></v-radio>
          </v-radio-group>
          <v-row v-if="feeChoosed === 'payAsYouGo'">
            <v-col cols="12">
              <v-text-field
                dense
                outlined
                v-model.number="form['one_time_fee']"
                :label="$t('__charge_plan_form_payasyougo_fee') + ' *'"
                required
                :rules="[requiredRule]"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row v-else>
            <v-col cols="4">
              <v-select
                v-model="form['regular_fee_cycle']"
                :items="regularFeeCycle"
                item-text="ch"
                item-value="en"
                :label="$t('__charge_plan_form_regular_cycle')"
                outlined
                dense
              ></v-select>
            </v-col>
            <v-col cols="8">
              <v-text-field
                dense
                outlined
                v-model.number="form['regular_fee']"
                :label="$t('__charge_plan_form_regular_fee') + ' *'"
                required
                :rules="[requiredRule]"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <div class="text-h7 mb-2">
                {{ $t("__charge_plan_form_transaction_fees_percentage") }}
              </div>
              <div class="outline-div">
                <!-- transaction fee item -->
                <template v-for="(fee, i) of transactionFee">
                  <div :key="`item-${i}`" class="d-flex align-center">
                    <v-text-field
                      hide-details
                      class="ma-3"
                      dense
                      outlined
                      :label="
                        $t('__charge_plan_form_transaction_fees_amount') + ' *'
                      "
                      required
                      v-model="fee.amount"
                      :rules="[requiredRule]"
                    ></v-text-field>
                    <v-text-field
                      class="ma-3"
                      hide-details
                      dense
                      outlined
                      :label="
                        $t('__charge_plan_form_transaction_fees_percent') + ' *'
                      "
                      required
                      v-model.number="fee.percentage"
                      :rules="[requiredRule]"
                    ></v-text-field>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          class="ma-2"
                          icon
                          v-bind="attrs"
                          v-on="on"
                          @click="deleteTransactionFee(i)"
                        >
                          <v-icon large color="grey lighten-1"
                            >mdi-minus-circle-outline</v-icon
                          >
                        </v-btn>
                      </template>
                      <span>{{ $t("__delete") }}</span>
                    </v-tooltip>
                  </div>
                  <v-divider :key="`item-${i}-divider`" />
                </template>
                <!-- add button -->
                <div class="d-flex justify-center">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="ma-2"
                        icon
                        v-bind="attrs"
                        v-on="on"
                        @click="addTransactionFee"
                      >
                        <v-icon large color="grey lighten-1"
                          >mdi-plus-circle-outline</v-icon
                        >
                      </v-btn>
                    </template>
                    <span>{{ $t("__create") }}</span>
                  </v-tooltip>
                </div>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <div class="text-h7 mb-2">
                {{ $t("__charge_plan_form_platform_setting") }}
              </div>
              <div class="d-flex">
                <v-checkbox
                  class="mr-5"
                  hide-details
                  v-model="form['allow_telegram_bot_chat']"
                  :label="$t('__charge_plan_form_telegram_bot_chat')"
                ></v-checkbox>
                <v-checkbox
                  hide-details
                  v-model="form['allow_one_page_web']"
                  :label="$t('__charge_plan_form_one_page_web')"
                ></v-checkbox>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <div class="text-h7 mb-2">
                {{ $t("__charge_plan_form_payment_setting") }}
              </div>
              <div class="d-flex">
                <v-checkbox
                  class="mr-5"
                  hide-details
                  v-model="form['allow_payment_cash']"
                  :label="$t('__charge_plan_form_payment_cash')"
                ></v-checkbox>
                <v-checkbox
                  class="mr-5"
                  hide-details
                  v-model="form['allow_payment_cash_on_delivery']"
                  :label="$t('__charge_plan_form_payment_cash_on_delivery')"
                ></v-checkbox>
                <v-checkbox
                  class="mr-5"
                  hide-details
                  v-model="form['allow_payment_atm']"
                  :label="$t('__charge_plan_form_payment_atm')"
                ></v-checkbox>
                <v-checkbox
                  class="mr-5"
                  hide-details
                  v-model="form['allow_payment_tappay']"
                  :label="$t('__charge_plan_form_payment_tappay')"
                ></v-checkbox>
                <v-checkbox
                  hide-details
                  v-model="form['allow_payment_ecpay']"
                  :label="$t('__charge_plan_form_payment_ecpay')"
                ></v-checkbox>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <div class="text-h7 mb-2">
                {{ $t("__charge_plan_form_shipping_setting") }}
              </div>
              <div class="d-flex">
                <v-checkbox
                  class="mr-5"
                  hide-details
                  v-model="form['allow_shipping_pickup']"
                  :label="$t('__charge_plan_form_shipping_pickup')"
                ></v-checkbox>
                <v-checkbox
                  hide-details
                  v-model="form['allow_shipping_logistics']"
                  :label="$t('__charge_plan_form_shipping_logistics')"
                ></v-checkbox>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <div class="text-h7 mb-2">
                {{ $t("__charge_plan_form_store_setting") }}
              </div>
              <v-checkbox
                class="mb-2"
                hide-details
                v-model="publishedItemUnlimited"
                :label="
                  $t('__charge_plan_form_published_item_quantity_unlimited')
                "
              ></v-checkbox>
              <v-text-field
                type="number"
                dense
                outlined
                v-model.number="form['allow_published_item_quantity']"
                :label="$t('__charge_plan_form_published_item_quantity')"
                :disabled="publishedItemUnlimited"
              ></v-text-field>
              <div class="text-caption mb-2">
                {{ $t("__charge_plan_form_store_message") }}
              </div>
              <v-checkbox
                class="mb-2"
                hide-details
                v-model="messageUnlimited"
                :label="$t('__charge_plan_form_store_message_unlimited')"
              ></v-checkbox>
              <v-row>
                <v-col cols="4">
                  <v-select
                    :items="messageInterval"
                    item-text="ch"
                    item-value="en"
                    v-model="form['allow_push_store_message_interval']"
                    outlined
                    dense
                    :disabled="messageUnlimited"
                  />
                </v-col>
                <v-col cols="8">
                  <v-text-field
                    type="number"
                    dense
                    outlined
                    :label="$t('__charge_plan_form_store_message_quantity')"
                    v-model.number="form['allow_push_store_message_quantity']"
                    :disabled="messageUnlimited"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="blue darken-1" text @click="cancel">{{ formcancel }}</v-btn>
      <v-btn
        color="blue darken-1"
        text
        @click="ok"
        :disabled="!isValid"
        v-if="action !== 'see'"
        >{{ formok }}</v-btn
      >
    </v-card-actions>
  </v-card>
</template>
<script>
import { mapGetters } from "vuex";
import { format, formatISO } from "date-fns";
import { VueEditor } from "vue2-editor";

export default {
  name: "PlanForm",
  components: {
    VueEditor
  },
  computed: {
    ...mapGetters({
      isMobile: "isMobile"
    })
  },
  props: {
    action: {
      type: String,
      required: true
    },
    planData: {
      required: true
    }
  },
  data() {
    return {
      formtitle: "",
      formok: "",
      formcancel: "",
      regularFeeCycle: [
        {
          en: "month",
          ch: this.$t("__charge_plan_form_regular_month")
        },
        {
          en: "year",
          ch: this.$t("__charge_plan_form_regular_year")
        }
      ],
      messageInterval: [
        {
          en: "day",
          ch: this.$t("__charge_plan_form_message_day")
        },
        {
          en: "week",
          ch: this.$t("__charge_plan_form_message_week")
        },
        {
          en: "month",
          ch: this.$t("__charge_plan_form_message_month")
        },
        {
          en: "year",
          ch: this.$t("__charge_plan_form_message_year")
        }
      ],
      contractDuration: [
        {
          en: "day",
          ch: this.$t("__charge_plan_form_contract_duration_day")
        },
        {
          en: "month",
          ch: this.$t("__charge_plan_form_contract_duration_month")
        },
        {
          en: "year",
          ch: this.$t("__charge_plan_form_contract_duration_year")
        }
      ],
      form: {
        name: "",
        start_time: format(new Date(), "yyyy-MM-dd"),
        end_time: "",
        contract_duration_unit: "year",
        contract_duration: 0,
        contract_terms: "",
        one_time_fee: 0,
        regular_fee: 0,
        regular_fee_cycle: "month",
        transaction_fees_percentage: {},
        allow_telegram_bot_chat: false,
        allow_one_page_web: false,
        allow_payment_cash: false,
        allow_payment_cash_on_delivery: false,
        allow_payment_atm: false,
        allow_payment_tappay: false,
        allow_payment_ecpay: false,
        allow_shipping_pickup: false,
        allow_shipping_logistics: false,
        allow_published_item_quantity: 0, //-1 unlimited
        allow_push_store_message_interval: "day",
        allow_push_store_message_quantity: 0 //-1 unlimited
      },
      transactionFee: [
        {
          amount: "",
          percentage: ""
        }
      ],
      publishedItemUnlimited: false,
      messageUnlimited: false,
      feeChoosed: "payAsYouGo",
      isValid: false,
      requiredRule: v => !!v || this.$t("__required")
    };
  },
  methods: {
    addTransactionFee() {
      this.transactionFee.push({
        amount: "",
        percentage: ""
      });
    },
    deleteTransactionFee(i) {
      if (this.transactionFee.length === 1) return;
      this.transactionFee.splice(i, 1);
    },
    ok() {
      let startTime = formatISO(new Date(this.form["start_time"]));
      let endTime = formatISO(new Date(this.form["end_time"]));
      for (const fee of this.transactionFee) {
        this.form["transaction_fees_percentage"][fee.amount] = fee.percentage;
      }
      const setData = {
        name: this.form.name,
        start_time: startTime,
        end_time: endTime,
        contract_duration: this.form["contract_duration"],
        contract_duration_unit: this.form["contract_duration_unit"],
        contract_terms: this.form["contract_terms"],
        one_time_fee: this.form["one_time_fee"],
        regular_fee: this.form["regular_fee"],
        regular_fee_cycle: this.form["regular_fee_cycle"],
        transaction_fees_percentage: this.form["transaction_fees_percentage"],
        allow_telegram_bot_chat: this.form["allow_telegram_bot_chat"],
        allow_one_page_web: this.form["allow_one_page_web"],
        allow_payment_cash: this.form["allow_payment_cash"],
        allow_payment_cash_on_delivery: this.form[
          "allow_payment_cash_on_delivery"
        ],
        allow_payment_atm: this.form["allow_payment_atm"],
        allow_payment_tappay: this.form["allow_payment_tappay"],
        allow_payment_ecpay: this.form["allow_payment_ecpay"],
        allow_shipping_pickup: this.form["allow_shipping_pickup"],
        allow_shipping_logistics: this.form["allow_shipping_logistics"],
        allow_published_item_quantity: this.publishedItemUnlimited
          ? -1
          : this.form["allow_published_item_quantity"],
        allow_push_store_message_interval: this.form[
          "allow_push_store_message_interval"
        ],
        allow_push_store_message_quantity: this.messageUnlimited
          ? -1
          : this.form["allow_push_store_message_quantity"]
      };
      if (this.action === "create") this.initForm();
      this.$emit("ok", setData);
    },
    cancel() {
      if (this.action === "create") this.initForm();
      this.$emit("cancel");
    },
    initForm() {
      this.form = {
        name: "",
        start_time: format(new Date(), "yyyy-MM-dd"),
        end_time: "",
        contract_duration_unit: "year",
        contract_duration: 0,
        contract_terms: "",
        one_time_fee: 0,
        regular_fee: 0,
        regular_fee_cycle: "month",
        transaction_fees_percentage: {},
        allow_telegram_bot_chat: false,
        allow_one_page_web: false,
        allow_payment_cash: false,
        allow_payment_cash_on_delivery: false,
        allow_payment_atm: false,
        allow_payment_tappay: false,
        allow_payment_ecpay: false,
        allow_shipping_pickup: false,
        allow_shipping_logistics: false,
        allow_published_item_quantity: 0, //-1 unlimited
        allow_push_store_message_interval: "day",
        allow_push_store_message_quantity: 0 //-1 unlimited
      };
      this.transactionFee = [
        {
          amount: "",
          percentage: ""
        }
      ];
      this.messageUnlimited = false;
      this.publishedItemUnlimited = false;
      this.feeChoosed = "payAsYouGo";
    }
  },
  watch: {
    action: {
      immediate: true,
      handler(val) {
        if (val === "create") {
          this.formtitle = this.$t("__charge_plan_create");
          this.formok = this.$t("__create");
          this.formcancel = this.$t("__cancel");
        } else if (val === "edit") {
          this.formtitle = this.$t("__charge_plan_edit");
          this.formok = this.$t("__confirm");
          this.formcancel = this.$t("__cancel");
        } else {
          this.formtitle = this.$t("__charge_plan_content");
          this.formcancel = this.$t("__close");
        }
      }
    },
    planData: {
      immediate: true,
      deep: true,
      handler(val) {
        this.form = {
          name: "",
          start_time: format(new Date(), "yyyy-MM-dd"),
          end_time: "",
          contract_duration_unit: "year",
          contract_duration: 1,
          contract_terms: "",
          one_time_fee: 0,
          regular_fee: 0,
          regular_fee_cycle: "month",
          transaction_fees_percentage: {},
          allow_telegram_bot_chat: false,
          allow_one_page_web: false,
          allow_payment_cash: false,
          allow_payment_cash_on_delivery: false,
          allow_payment_atm: false,
          allow_payment_tappay: false,
          allow_payment_ecpay: false,
          allow_shipping_pickup: false,
          allow_shipping_logistics: false,
          allow_published_item_quantity: 0, //-1 unlimited
          allow_push_store_message_interval: "day",
          allow_push_store_message_quantity: 0 //-1 unlimited
        };
        this.transactionFee = [
          {
            amount: "",
            percentage: ""
          }
        ];
        this.publishedItemUnlimited = false;
        this.messageUnlimited = false;
        if (val) {
          if (val["name"]) this.form.name = val["name"];
          if (val["contract_duration_unit"])
            this.form["contract_duration_unit"] = val["contract_duration_unit"];
          if (val["contract_duration"])
            this.form["contract_duration"] = val["contract_duration"];
          if (val["contract_terms"])
            this.form["contract_terms"] = val["contract_terms"];
          if (val["one_time_fee"]) {
            this.feeChoosed = "payAsYouGo";
            this.form["one_time_fee"] = val["one_time_fee"];
          }
          if (val["regular_fee"]) {
            this.feeChoosed = "regular";
            this.form["regular_fee"] = val["regular_fee"];
          }
          if (val["regular_fee_cycle"])
            this.form["regular_fee_cycle"] = val["regular_fee_cycle"];
          if (val["start_time"]) {
            this.form["start_time"] = format(
              new Date(val["start_time"]),
              "yyyy-MM-dd"
            );
          }
          if (val["end_time"]) {
            this.form["end_time"] = format(
              new Date(val["end_time"]),
              "yyyy-MM-dd"
            );
          }
          if (val["transaction_fees_percentage"]) {
            const amountAry = Object.keys(val["transaction_fees_percentage"]);
            const percentageAry = Object.values(
              val["transaction_fees_percentage"]
            );
            for (let i = 0; i < amountAry.length; i++) {
              this.transactionFee.splice(i, 1, {
                amount: amountAry[i],
                percentage: percentageAry[i]
              });
            }
          }
          if (val["allow_telegram_bot_chat"])
            this.form["allow_telegram_bot_chat"] =
              val["allow_telegram_bot_chat"];
          if (val["allow_one_page_web"])
            this.form["allow_one_page_web"] = val["allow_one_page_web"];
          val["allow_telegram_bot_chat"];
          if (val["allow_payment_cash"])
            this.form["allow_payment_cash"] = val["allow_payment_cash"];
          if (val["allow_payment_cash_on_delivery"])
            this.form["allow_payment_cash_on_delivery"] =
              val["allow_payment_cash_on_delivery"];
          if (val["allow_payment_atm"])
            this.form["allow_payment_atm"] = val["allow_payment_atm"];
          if (val["allow_payment_tappay"])
            this.form["allow_payment_tappay"] = val["allow_payment_tappay"];
          if (val["allow_payment_ecpay"])
            this.form["allow_payment_ecpay"] = val["allow_payment_ecpay"];
          if (val["allow_shipping_pickup"])
            this.form["allow_shipping_pickup"] = val["allow_shipping_pickup"];
          if (val["allow_shipping_logistics"])
            this.form["allow_shipping_logistics"] =
              val["allow_shipping_logistics"];
          if (val["allow_published_item_quantity"] !== -1) {
            this.form["allow_published_item_quantity"] =
              val["allow_published_item_quantity"];
          } else {
            this.publishedItemUnlimited = true;
          }

          if (val["allow_push_store_message_interval"])
            this.form["allow_push_store_message_interval"] =
              val["allow_push_store_message_interval"];
          if (val["allow_push_store_message_quantity"] !== -1) {
            this.form["allow_push_store_message_quantity"] =
              val["allow_push_store_message_quantity"];
          } else {
            this.messageUnlimited = true;
          }
        }
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~vue2-editor/dist/vue2-editor.css";
::v-deep .ql-editor {
  max-height: 300px;
  overflow-y: auto;
}
.outline-div {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.38);
  border-radius: 4px;
}
</style>
