<template>
  <div class="manage-store-level">
    <v-card elevation="0">
      <v-card-title>
        {{ $t("__managechargeplan_title") }}
        <v-btn color="primary" class="ml-auto" @click="toCreatePlan">{{
          $t("__charge_plan_create")
        }}</v-btn>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col :cols="isMobile ? 12 : 3">
            <v-select
              :label="$t('__charge_plan_status')"
              :items="planStatus"
              item-text="ch"
              item-value="en"
              outlined
              dense
              v-model="statusSelect"
            />
          </v-col>
        </v-row>
        <v-card>
          <v-data-table
            :headers="headers"
            :items="items"
            :custom-sort="customSort"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
          >
            <template v-slot:[`item.published`]="{ item }">
              <template v-if="item.published && !item.delisted">
                <v-chip class="ma-1" label small color="green white--text">
                  {{ $t("__charge_plan_published") }}
                </v-chip>
                <v-btn class="ma-1" x-small @click="toDelistPlan(item)">{{
                  $t("__charge_plan_delist")
                }}</v-btn>
              </template>
              <template v-else-if="!item.published && !item.delisted">
                <v-chip class="ma-1" label small color="red white--text">
                  {{ $t("__charge_plan_unpublished") }}
                </v-chip>
                <v-btn class="ma-1" x-small @click="toPublishPlan(item)">{{
                  $t("__charge_plan_publish")
                }}</v-btn>
              </template>
              <template v-else>
                <v-chip class="ma-1" label small color="red white--text">
                  {{ $t("__charge_plan_delisted") }}
                </v-chip>
                <v-btn class="ma-1" x-small @click="toPublishPlan(item)">{{
                  $t("__charge_plan_publish")
                }}</v-btn>
              </template>
            </template>
            <template v-slot:[`item.endTime`]="{ item }">
              {{ item.endTime }}
              <v-chip small color="red" dark v-if="item.threeMonthsExpired">
                {{ $t("__charge_plan_near_expired") }}
              </v-chip>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <template v-if="!item.delisted && !item.published">
                <v-icon
                  class="ma-2"
                  small
                  color="darken-2"
                  @click="toEditPlan(item)"
                  >mdi-pencil</v-icon
                >
                <v-icon
                  class="ma-2"
                  small
                  color="red darken-2"
                  @click="toDeletePlan(item)"
                  >mdi-delete</v-icon
                >
              </template>
              <template v-else>
                <v-btn icon small @click="toSeeDetail(item)">
                  <v-icon>mdi-dots-horizontal-circle-outline</v-icon>
                </v-btn>
              </template>
            </template>
          </v-data-table>
        </v-card>
      </v-card-text>
    </v-card>
    <v-dialog v-model="planFormShow" persistent max-width="800px">
      <PlanForm
        :action="planFormAction"
        :planData="planFormplanData"
        @ok="planFormOk"
        @cancel="planFormCancel"
      />
    </v-dialog>
    <AlertDialog
      :show="alertDialogShow"
      :title="alertDialogTitle"
      :text="alertDialogText"
      :oktext="$t('__confirm')"
      :canceltext="$t('__cancel')"
      @ok="alertDialogOk"
      @cancel="alertDialogCancel"
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { format, subMonths } from "date-fns";
import PlanForm from "@/components/Admin/PlanForm";

export default {
  name: "ManageChargePlan",
  components: {
    PlanForm
  },
  computed: {
    ...mapGetters({
      plans: "plan/list",
      isMobile: "isMobile"
    }),
    items() {
      const plans = [];
      for (const planItem of this.plans) {
        const item = {
          id: planItem.id,
          name: planItem.data.name,
          startTime: format(
            new Date(planItem.data["start_time"]),
            "yyyy/MM/dd"
          ),
          threeMonthsExpired: this.judgeExpired(planItem.data["end_time"]),
          endTime: format(new Date(planItem.data["end_time"]), "yyyy/MM/dd"),
          delisted: planItem.data["is_delisted"],
          published: planItem.data["is_published"],
          data: planItem.data
        };
        let statuses = [];
        if (planItem.data["is_delisted"]) {
          statuses.push("delisted");
        } else if (
          !planItem.data["is_delisted"] &&
          !planItem.data["is_published"]
        ) {
          statuses.push("unpublished");
        } else {
          statuses.push("published");
        }

        const startTimestamp = format(
          new Date(planItem.data["start_time"]),
          "t"
        );
        const endTimestamp = format(new Date(planItem.data["end_time"]), "t");
        const todayTimeStamp = format(new Date(), "t");
        if (startTimestamp > todayTimeStamp) {
          statuses.push("not-started");
        } else if (
          startTimestamp < todayTimeStamp &&
          endTimestamp > todayTimeStamp
        ) {
          statuses.push("started");
        } else {
          statuses.push("expired");
        }
        item.statuses = statuses;
        plans.push(item);
      }
      return plans;
    }
  },
  data() {
    return {
      statusSelect: "all",
      planStatus: [
        {
          en: "all",
          ch: this.$t("__charge_plan_all")
        },
        {
          en: "unpublished",
          ch: this.$t("__charge_plan_unpublished")
        },
        {
          en: "published",
          ch: this.$t("__charge_plan_published")
        },
        {
          en: "delisted",
          ch: this.$t("__charge_plan_delisted")
        },
        {
          en: "not-started",
          ch: this.$t("__charge_plan_not-started")
        },
        {
          en: "started",
          ch: this.$t("__charge_plan_started")
        },
        {
          en: "expired",
          ch: this.$t("__charge_plan_expired")
        }
      ],
      headers: [
        {
          text: this.$t("__charge_plan_headers_name"),
          align: "start",
          sortable: false,
          value: "name"
        },
        {
          text: this.$t("__charge_plan_headers_published"),
          align: "start",
          value: "published"
        },
        {
          text: this.$t("__charge_plan_form_start_time"),
          align: "start",
          value: "startTime"
        },
        {
          text: this.$t("__charge_plan_form_end_time"),
          align: "start",
          value: "endTime"
        },
        {
          text: this.$t("__charge_plan_headers_actions"),
          align: "start",
          sortable: false,
          value: "actions"
        }
      ],
      sortBy: "published",
      sortDesc: true,
      planFormShow: false,
      planFormAction: "create",
      planFormplanID: "",
      planFormplanData: null,
      alertDialogShow: false,
      alertDialogTitle: "",
      alertDialogText: "",
      alertDialogFunc: null,
      alertDialogData: null
    };
  },
  methods: {
    loadPlans(status) {
      this.$store.dispatch("setIsLoading", true);
      return this.$store
        .dispatch("plan/getChargePlans", status)
        .then(() => {
          this.$store.dispatch("setIsLoading", false);
        })
        .catch(() => {
          this.$store.dispatch("setIsLoading", false);
        });
    },
    toCreatePlan() {
      this.planFormShow = true;
      this.planFormAction = "create";
      this.planFormplanID = "";
      this.planFormplanData = null;
    },
    toEditPlan(item) {
      this.planFormAction = "edit";
      this.planFormplanID = item.id;
      this.planFormplanData = item.data;
      this.planFormShow = true;
    },
    toSeeDetail(item) {
      this.planFormAction = "see";
      this.planFormplanID = item.id;
      this.planFormplanData = item.data;
      this.planFormShow = true;
    },
    toPublishPlan(item) {
      this.alertDialogTitle = this.$t("__charge_plan_publish_title");
      this.alertDialogText = this.$t("__charge_plan_publish_text").replace(
        "%1",
        item.name
      );
      this.alertDialogFunc = this.publishPlan;
      this.alertDialogData = item.id;
      this.alertDialogShow = true;
    },
    toDelistPlan(item) {
      this.alertDialogTitle = this.$t("__charge_plan_delist_title");
      this.alertDialogText = this.$t("__charge_plan_delist_text").replace(
        "%1",
        item.name
      );
      this.alertDialogFunc = this.delistPlan;
      this.alertDialogData = item.id;
      this.alertDialogShow = true;
    },
    toDeletePlan(item) {
      this.alertDialogTitle = this.$t("__charge_plan_delete_title");
      this.alertDialogText = this.$t("__charge_plan_delete_text").replace(
        "%1",
        item.name
      );
      this.alertDialogFunc = this.deletePlan;
      this.alertDialogData = item.id;
      this.alertDialogShow = true;
    },
    publishPlan(id) {
      this.$store.dispatch("setIsLoading", true);
      return this.$store
        .dispatch("plan/publishPlan", id)
        .then(() => {
          this.loadPlans();
        })
        .catch(err => {
          console.log(err);
          this.$store.dispatch("setIsLoading", false);
        });
    },
    delistPlan(id) {
      this.$store.dispatch("setIsLoading", true);
      return this.$store
        .dispatch("plan/delistPlan", id)
        .then(() => {
          this.loadPlans();
        })
        .catch(err => {
          console.log(err);
          this.$store.dispatch("setIsLoading", false);
        });
    },
    deletePlan(id) {
      this.$store.dispatch("setIsLoading", true);
      return this.$store
        .dispatch("plan/deletePlan", id)
        .then(() => {
          this.loadPlans();
        })
        .catch(err => {
          console.log(err);
          this.$store.dispatch("setIsLoading", false);
        });
    },
    creatPlan(name, setData) {
      this.$store.dispatch("setIsLoading", true);
      return this.$store
        .dispatch("plan/createPlan", { name, setData })
        .then(() => {
          this.loadPlans();
        })
        .catch(err => {
          console.log(err);
          this.$store.dispatch("setIsLoading", false);
        });
    },
    editPlan(setData) {
      let planID = this.planFormplanID;
      this.$store.dispatch("setIsLoading", true);
      return this.$store
        .dispatch("plan/setPlan", { planID, setData })
        .then(() => {
          this.loadPlans();
        })
        .catch(err => {
          console.log(err);
          this.$store.dispatch("setIsLoading", false);
        });
    },
    planFormOk(setData) {
      let name = setData.name;
      this.planFormShow = false;
      if (this.planFormAction === "create") {
        this.creatPlan(name, setData);
      } else {
        this.editPlan(setData);
      }
    },
    planFormCancel() {
      this.planFormShow = false;
    },
    alertDialogOk() {
      this.alertDialogShow = false;
      if (this.alertDialogFunc) this.alertDialogFunc(this.alertDialogData);
      this.alertDialogTitle = "";
      this.alertDialogText = "";
      this.alertDialogFunc = null;
      this.alertDialogData = null;
    },
    alertDialogCancel() {
      this.alertDialogShow = false;
      this.alertDialogTitle = "";
      this.alertDialogText = "";
      this.alertDialogFunc = null;
      this.alertDialogData = null;
    },
    judgeExpired(time) {
      let today = format(new Date(), "T");
      let threeMonthDate = subMonths(new Date(time), 3);
      let dateTimeStamp = format(threeMonthDate, "T");
      if (today >= dateTimeStamp) return true;
      else return false;
    },
    customSort(items, index, isDesc) {
      items.sort((a, b) => {
        if (index[0] === "published") {
          if (!isDesc) {
            return b.delisted - a.delisted;
          } else {
            return a.delisted - b.delisted;
          }
        } else {
          if (!isDesc) {
            return a[index] < b[index] ? -1 : 1;
          } else {
            return b[index] < a[index] ? -1 : 1;
          }
        }
      });
      return items;
    }
  },
  watch: {
    statusSelect: {
      immediate: true,
      handler(val) {
        let status = "";
        if (val === "all") status = "";
        else status = val;
        this.loadPlans(status);
      }
    }
  }
};
</script>
